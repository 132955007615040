import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import Dropdown from './Dropdown';


function Navbar() {
    return (
        <>
        <div className='navbar'>
            <Link to={"/"} style={{textDecoration: 'none'}}><div id='logo'>
                Accidents Illinois
                <div id='logo-subtitle'>Legal Experts</div>
            </div></Link>
            <Dropdown 
                base={{text: "RESOURCES", link: "resources"}} 
                list={[
                    {text: "Personal Injury Law", link: "personal-injury"},
                    {text: "Worker's Compensation Law", link: "workers-comp"},
                    {text: "Car Accident Law", link: "car-accidents"},
                    {text: "Slip and Fall Law", link: "slip-and-fall"},
                    {text: "VIEW ALL", link: "resources"},
                ]}
            />
            <Dropdown 
                base={{text: "Q & A", link: "qna"}} 
                list={[
                    {text: "Legal Questions", link: "q-legal"},
                    {text: "How Lawyers Work", link: "q-lawyers"},
                    {text: "Legal Process", link: "q-process"},
                    {text: "About Us", link: "about"},
                    {text: "VIEW ALL", link: "qna"},
                ]}
            />
            <Dropdown 
                base={{text: "BLOG", link: "blog"}} 
                list={[
                    {text: "By Topic", link: "blog/topics"},
                    {text: "By Date", link: "blog/date"}
                ]}
            />
            <Link to={'contact-us'}><div className='navbutton'>DO I HAVE A CASE?</div></Link>

            <div className='navbutton navright'>EN ESPAÑOL</div>
        </div>
        <div className='navbarspacer'/>
        </>
    );
}

export default Navbar;
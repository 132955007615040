import React, {Component} from 'react';
import {Link} from "react-router-dom";

function Home() {
    return (
        <>
            <div className='homepage'>
                <h1>
                    Accidents happen.<br/>
                    Let us help.
                </h1>
                <input type='text' id='searchbar' placeholder='Search legal resources'></input><div id='searchbutton'>GO</div>
                <Link to={'about'}><div className='sublink'>WHO WE ARE</div></Link>
                <Link to={'contact-us'}><div className='sublink'>DO I HAVE A CASE?</div></Link>

                <div style={{height: '150px'}}></div>

                <div className='flex-container article-container'>
                    <div className='flex-section'>
                        <div className='heading'>Recent Articles</div>
                    </div>
                    <div className='flex-section'>
                        <div className='heading'>Popular</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home;
import React, {Component} from 'react';
import {
  Router,
  Routes,
  Route,
  Link,
  Outlet,
  useParams
} from "react-router-dom";

import Navbar from './Navbar';
import Home from './Home';

import './App.css';

// Model website content after questions answered on hg.org
// https://www.hg.org/car-accident.html

function App() {
  return (
    <>
    <link rel="preconnect" href="https://fonts.googleapis.com"/>
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
    <link href="https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Gabarito&family=Josefin+Sans&family=Urbanist&display=swap" rel="stylesheet"/>

    
    <Navbar/>

    <Routes>
      <Route path="/" element={<Home />}/>
    </Routes>
    </>
  );
}

export default App;
